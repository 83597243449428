<ng-container *ngIf="productDetails$ | async as product">
  <div class="p-image">
    <img (click)="openModal(array[current])" src="{{array[current]}}" class="d-block mw-100 carousel-img" alt="product-image">
    <div class="left-arrow mt-1 mb-3 carousel-nav-container">
      <a><i class="fa fa-chevron-left" (click)="prevSlide()"> </i></a><span class="carousel-number-active"
        data-v-2dcb6341="">{{current +1 | number:'2.0'}}</span>
      <span class="carousel-number-total">{{lengthArray| number:'2.0'}}</span>
      <a><i (click)="nextSlide()" class="fa fa-chevron-right"></i></a>
    </div>
  </div>
  <button id="zoomModalButton" [hidden]="true" data-toggle="modal" data-target="#zoomModal"></button>
</ng-container>
<div class="modal fade" tabindex="-1" #zoomModal id="zoomModal" role="dialog" aria-labelledby="zoomModalTitle"
  aria-hidden="true">
  <div class="modal-dialog popup-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="closeVideo">
        <ng-magnizoom [imageClass]="{ 'custom-image': true }"
          [imageStyle]="{ 'max-width': '100%', 'max-height': '100%' }" imageSrc="{{zoomImageSrc}}"></ng-magnizoom>
      </div>
    </div>
  </div>
</div>