import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["mainCanvas"];
class MagnizoomService {
  constructor() {}
}
/** @nocollapse */
MagnizoomService.ɵfac = function MagnizoomService_Factory(t) {
  return new (t || MagnizoomService)();
};
/** @nocollapse */
MagnizoomService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: MagnizoomService,
  factory: MagnizoomService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MagnizoomService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class NgMagnizoomComponent {
  get canvasWidth() {
    return this.image && this.image.width || 800;
  }
  get canvasHeight() {
    return this.image && this.image.height || 600;
  }
  constructor() {
    this.zoomMode = 'COVER';
    this.minZoomFactor = 1.2;
    this.maxZoomFactor = 3;
    this.zoomFactor = 2;
    this.zoomFactorChange = new EventEmitter();
    this.lensSizeUnit = 'NORMALIZED';
    this.lensSize = {
      width: 0.5,
      height: 0.5
    };
    this.zoomCenterUnit = 'NORMALIZED';
    this.zoomCenterChange = new EventEmitter();
    this.updateOnMouseEvents = true;
    this.imageReady = false;
  }
  ngOnInit() {
    this.initContext();
    this.loadImage(this.imageSrc);
  }
  ngOnChanges(changes) {
    if (!changes) {
      return;
    }
    if (changes.lensSize || changes.zoomCenter || changes.zoomFactor) {
      this.updateParameters();
    }
    if (changes.imageSrc && !changes.imageSrc.firstChange) {
      this.loadImage(changes.imageSrc.currentValue);
    }
  }
  initContext() {
    this.canvas = this.mainCanvasRef.nativeElement;
    this.context = this.canvas.getContext('2d');
  }
  loadImage(src) {
    this.image = new Image();
    this.image.onload = () => {
      this.imageReady = true;
      this.updateParameters();
      setTimeout(() => this.update());
    };
    this.image.src = src;
  }
  updateParameters() {
    if (this.lensSizeUnit === 'NORMALIZED') {
      if (this.imageReady) {
        this._lensSize = {
          width: this.lensSize.width * this.image.width,
          height: this.lensSize.height * this.image.height
        };
      }
    } else {
      this._lensSize = {
        width: this.lensSize.width,
        height: this.lensSize.height
      };
    }
    if (!this.zoomCenter) {
      this._centerPosition = undefined;
    } else if (this.zoomCenterUnit === 'NORMALIZED') {
      if (this.imageReady) {
        this._centerPosition = {
          x: this.zoomCenter.x * this.image.width,
          y: this.zoomCenter.y * this.image.height
        };
      }
    } else {
      this._centerPosition = {
        x: this.zoomCenter.x,
        y: this.zoomCenter.y
      };
    }
    this._zoomFactor = this.zoomFactor;
    if (this._zoomFactor > this.maxZoomFactor) {
      this._zoomFactor = this.maxZoomFactor;
    }
    if (this._zoomFactor < this.minZoomFactor) {
      this._zoomFactor = this.minZoomFactor;
    }
    this.update();
  }
  update() {
    this.render();
    let currUnitCenter,
      needUpdate = false;
    if (!this._centerPosition) {
      currUnitCenter = undefined;
      needUpdate = currUnitCenter !== this.zoomCenter;
    } else if (this.zoomCenterUnit === 'NORMALIZED') {
      if (this.imageReady) {
        currUnitCenter = {
          x: this._centerPosition.x / this.image.width,
          y: this._centerPosition.y / this.image.height
        };
        needUpdate = currUnitCenter.x !== this.zoomCenter?.x || currUnitCenter.y !== this.zoomCenter?.y;
      }
    } else {
      currUnitCenter = {
        x: this._centerPosition.x,
        y: this._centerPosition.y
      };
      needUpdate = currUnitCenter.x !== this.zoomCenter?.x || currUnitCenter.y !== this.zoomCenter?.y;
    }
    if (needUpdate) {
      this.zoomCenterChange.emit(currUnitCenter);
    }
  }
  render() {
    if (!this.context || !this.imageReady) {
      return;
    }
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight); // clear canvas
    this.context.lineWidth = 1; // border width
    this.context.drawImage(this.image, 0, 0); // bg image
    if (this._centerPosition) {
      switch (this.zoomMode) {
        case 'LENS':
          this.renderLensMode();
          break;
        case 'COVER':
          this.renderCoverMode();
          break;
      }
    }
  }
  renderLensMode() {
    this.context.lineWidth = 5; // border width
    const zoomRect = this.getZoomRect();
    this.context.fillRect(zoomRect.x, zoomRect.y, zoomRect.w, zoomRect.h); // bg (clear)
    const clippingRect = this.getClippingRect();
    // zoom image
    this.context.drawImage(this.image, clippingRect.x, clippingRect.y, clippingRect.w, clippingRect.h, zoomRect.x, zoomRect.y, zoomRect.w, zoomRect.h);
    this.context.strokeRect(zoomRect.x, zoomRect.y, zoomRect.w, zoomRect.h); // border
  }
  renderCoverMode() {
    const covertRect = this.getCoverRect();
    this.context.drawImage(this.image, covertRect.x, covertRect.y, covertRect.w, covertRect.h, 0, 0, this.canvasWidth, this.canvasHeight); // cover image
  }
  getZoomRect() {
    const w = this._lensSize.width;
    const h = this._lensSize.height;
    const x = this._centerPosition.x - w / 2;
    const y = this._centerPosition.y - h / 2;
    return this.clampRect(x, y, w, h);
  }
  getClippingRect() {
    const w = this._lensSize.width / this._zoomFactor;
    const h = this._lensSize.height / this._zoomFactor;
    const x = this._centerPosition.x - w / 2;
    const y = this._centerPosition.y - h / 2;
    return this.clampRect(x, y, w, h);
  }
  getCoverRect() {
    const w = this.canvasWidth / this._zoomFactor;
    const h = this.canvasHeight / this._zoomFactor;
    // const x = this.mousePosition.x - (w / 2);
    // const y = this.mousePosition.y - (h / 2);
    const x = this._centerPosition.x - this._centerPosition.x / this._zoomFactor;
    const y = this._centerPosition.y - this._centerPosition.y / this._zoomFactor;
    return this.clampRect(x, y, w, h);
  }
  clampRect(x, y, w, h) {
    if (x <= 0) {
      x = 0;
    }
    if (x + w >= this.canvasWidth) {
      x = this.canvasWidth - w;
    }
    if (y < 0) {
      y = 0;
    }
    if (y + h >= this.canvasHeight) {
      y = this.canvasHeight - h;
    }
    return {
      x,
      y,
      w,
      h
    };
  }
  calculateMousePosition(clientX, clientY) {
    const boundingRect = this.canvas.getBoundingClientRect();
    const boundingRectX = clientX - boundingRect.left;
    const boundingRectY = clientY - boundingRect.top;
    let elementX = boundingRectX,
      elementY = boundingRectY;
    let elementWidth = boundingRect.width,
      elementHeight = boundingRect.height;
    const computedStyle = window.getComputedStyle(this.canvas, null);
    const domMatrix = new DOMMatrix(computedStyle.transform);
    if (!domMatrix.isIdentity) {
      const {
        a,
        b
      } = domMatrix;
      const transformRot = Math.atan2(b, a);
      const transformScale = Math.round(Math.sqrt(a * a + b * b) * 100) / 100;
      if (transformRot) {
        const boundingRectCenterX = boundingRect.width / 2;
        const boundingRectCenterY = boundingRect.height / 2;
        const dx = boundingRectX - boundingRectCenterX;
        const dy = boundingRectY - boundingRectCenterY;
        const d = Math.sqrt(dx * dx + dy * dy);
        const r = Math.atan2(dy, dx);
        elementWidth = this.canvas.clientWidth * transformScale;
        elementHeight = this.canvas.clientHeight * transformScale;
        elementX = elementWidth / 2 + d * Math.cos(r - transformRot);
        elementY = elementHeight / 2 + d * Math.sin(r - transformRot);
      }
    }
    const viewToModelX = this.canvasWidth / elementWidth;
    const viewToModelY = this.canvasHeight / elementHeight;
    const x = elementX * viewToModelX;
    const y = elementY * viewToModelY;
    this._centerPosition = {
      x,
      y
    };
  }
  onMouseLeave(event) {
    if (!this.updateOnMouseEvents) {
      return;
    }
    this._centerPosition = null;
    this.update();
  }
  onMouseEnterOrMove(event) {
    if (!this.updateOnMouseEvents) {
      return;
    }
    this.calculateMousePosition(event.clientX, event.clientY);
    this.update();
  }
  onMouseScroll(event) {
    if (!this.updateOnMouseEvents) {
      return;
    }
    let newZoomFactor = this._zoomFactor;
    newZoomFactor -= event.deltaY / 1000;
    if (newZoomFactor < this.minZoomFactor) {
      newZoomFactor = this.minZoomFactor;
    }
    if (newZoomFactor > this.maxZoomFactor) {
      newZoomFactor = this.maxZoomFactor;
    }
    if (this._zoomFactor !== newZoomFactor) {
      this._zoomFactor = newZoomFactor;
      if (this.zoomFactor !== this._zoomFactor) {
        this.zoomFactorChange.emit(this._zoomFactor);
      }
      this.update();
    }
    event.preventDefault();
    event.stopPropagation();
  }
}
/** @nocollapse */
NgMagnizoomComponent.ɵfac = function NgMagnizoomComponent_Factory(t) {
  return new (t || NgMagnizoomComponent)();
};
/** @nocollapse */
NgMagnizoomComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgMagnizoomComponent,
  selectors: [["ng-magnizoom"]],
  viewQuery: function NgMagnizoomComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mainCanvasRef = _t.first);
    }
  },
  inputs: {
    imageSrc: "imageSrc",
    zoomMode: "zoomMode",
    minZoomFactor: "minZoomFactor",
    maxZoomFactor: "maxZoomFactor",
    zoomFactor: "zoomFactor",
    lensSizeUnit: "lensSizeUnit",
    lensSize: "lensSize",
    zoomCenterUnit: "zoomCenterUnit",
    zoomCenter: "zoomCenter",
    updateOnMouseEvents: "updateOnMouseEvents",
    imageStyle: "imageStyle",
    imageClass: "imageClass"
  },
  outputs: {
    zoomFactorChange: "zoomFactorChange",
    zoomCenterChange: "zoomCenterChange"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 2,
  vars: 4,
  consts: [["mainCanvas", ""], [1, "main-canvas", 3, "mouseleave", "mouseenter", "mousemove", "wheel", "ngClass", "ngStyle", "width", "height"]],
  template: function NgMagnizoomComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "canvas", 1, 0);
      i0.ɵɵlistener("mouseleave", function NgMagnizoomComponent_Template_canvas_mouseleave_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onMouseLeave($event));
      })("mouseenter", function NgMagnizoomComponent_Template_canvas_mouseenter_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onMouseEnterOrMove($event));
      })("mousemove", function NgMagnizoomComponent_Template_canvas_mousemove_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onMouseEnterOrMove($event));
      })("wheel", function NgMagnizoomComponent_Template_canvas_wheel_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onMouseScroll($event));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", ctx.imageClass)("ngStyle", ctx.imageStyle)("width", ctx.canvasWidth)("height", ctx.canvasHeight);
    }
  },
  dependencies: [i1.NgClass, i1.NgStyle]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgMagnizoomComponent, [{
    type: Component,
    args: [{
      selector: 'ng-magnizoom',
      template: "<canvas #mainCanvas\n  class=\"main-canvas\"\n  [ngClass]=\"imageClass\"\n  [ngStyle]=\"imageStyle\"\n  [width]=\"canvasWidth\"\n  [height]=\"canvasHeight\"\n  (mouseleave)=\"onMouseLeave($event)\"\n  (mouseenter)=\"onMouseEnterOrMove($event)\"\n  (mousemove)=\"onMouseEnterOrMove($event)\"\n  (wheel)=\"onMouseScroll($event)\">\n</canvas>\n"
    }]
  }], function () {
    return [];
  }, {
    imageSrc: [{
      type: Input
    }],
    zoomMode: [{
      type: Input
    }],
    minZoomFactor: [{
      type: Input
    }],
    maxZoomFactor: [{
      type: Input
    }],
    zoomFactor: [{
      type: Input
    }],
    zoomFactorChange: [{
      type: Output
    }],
    lensSizeUnit: [{
      type: Input
    }],
    lensSize: [{
      type: Input
    }],
    zoomCenterUnit: [{
      type: Input
    }],
    zoomCenter: [{
      type: Input
    }],
    zoomCenterChange: [{
      type: Output
    }],
    updateOnMouseEvents: [{
      type: Input
    }],
    imageStyle: [{
      type: Input
    }],
    imageClass: [{
      type: Input
    }],
    mainCanvasRef: [{
      type: ViewChild,
      args: ['mainCanvas', {
        static: true
      }]
    }]
  });
})();
class NgMagnizoomModule {}
/** @nocollapse */
NgMagnizoomModule.ɵfac = function NgMagnizoomModule_Factory(t) {
  return new (t || NgMagnizoomModule)();
};
/** @nocollapse */
NgMagnizoomModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgMagnizoomModule
});
/** @nocollapse */
NgMagnizoomModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgMagnizoomModule, [{
    type: NgModule,
    args: [{
      declarations: [NgMagnizoomComponent],
      imports: [CommonModule],
      exports: [NgMagnizoomComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-magnizoom
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MagnizoomService, NgMagnizoomComponent, NgMagnizoomModule };
